<template>
  <div class="how-it-works" :class="{ 'how-it-works--dark': dark }">
    <h2 class="how-it-works__title light-primary-title">
      {{ $t('how_it_works.title') }}
    </h2>
    <div class="how-it-works__grid">
      <div class="how-it-works__block how-it-works__block--1">
        <figure class="how-it-works__block--image">
          <icon
            size="40"
            name="hugeicons:magic-wand-01"
          />
        </figure>
        <h3 class="how-it-works__block--title">
          {{ $t('how_it_works.block1_title') }}
        </h3>
        <span class="how-it-works__block--description">
          {{ $t('how_it_works.block1_description') }}
        </span>
      </div>
      <div class="how-it-works__block how-it-works__block--2">
        <figure class="how-it-works__block--image">
          <icon
            name="tdesign:object-storage"
            size="40"
          />
        </figure>
        <h3 class="how-it-works__block--title">
          {{ $t('how_it_works.block2_title') }}
        </h3>
        <span class="how-it-works__block--description">
          {{ $t('how_it_works.block2_description') }}
        </span>
      </div>
      <div class="how-it-works__block how-it-works__block--3">
        <figure class="how-it-works__block--image">
          <icon
            size="40"
            name="solar:gallery-check-linear"
          />
        </figure>
        <h3 class="how-it-works__block--title">
          {{ $t('how_it_works.block3_title') }}
        </h3>
        <span class="how-it-works__block--description">
          {{ $t('how_it_works.block3_description') }}
        </span>
      </div>
      <div class="how-it-works__center-block how-it-works__block--4">
        <div class="how-it-works__video-wrapper">
          <video
            ref="howItWorksVideo"
            class="how-it-works__video"
            src="/momentshare_promo_1-1_short_v005.mp4"
            playsinline
            loop
            muted
            poster="/video_poster.webp"
            height="1080"
            width="1080"
            preload="metadata"
          />
        </div>
      </div>
      <div class="how-it-works__block how-it-works__block--5">
        <figure class="how-it-works__block--image">
          <icon
            name="streamline:qr-code-solid"
            size="40"
          />
        </figure>
        <h3 class="how-it-works__block--title">
          {{ $t('how_it_works.block5_title') }}
        </h3>
        <span class="how-it-works__block--description">
          {{ $t('how_it_works.block5_description') }}
        </span>
      </div>
      <div class="how-it-works__block how-it-works__block--6">
        <figure class="how-it-works__block--image">
          <icon
            size="40"
            name="material-symbols:camera"
          />
        </figure>
        <h3 class="how-it-works__block--title">
          {{ $t('how_it_works.block6_title') }}
        </h3>
        <span class="how-it-works__block--description">
          {{ $t('how_it_works.block6_description') }}
        </span>
      </div>
      <div class="how-it-works__block how-it-works__block--7">
        <figure class="how-it-works__block--image">
          <icon
            size="40"
            name="mdi:upload-lock"
          />
        </figure>
        <h3 class="how-it-works__block--title">
          {{ $t('how_it_works.block7_title') }}
        </h3>
        <span class="how-it-works__block--description">
          {{ $t('how_it_works.block7_description') }}
        </span>
      </div>
    </div>
  </div>
</template>
<script setup>
  defineProps({
    dark: {
      type: Boolean,
      default: false,
    },
  })

  const videoObserver = ref(null)
  let videoPromise = null
  const howItWorksVideo = ref(null)

  function setVideoIntersectionObserver() {
    videoObserver.value = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        // Store video element
        const video = entry.target

        if (entry.isIntersecting && video.paused) {
          // Store video play promise
          videoPromise = video.play()
        } else {
          // Only pause video when promise is fulfilled
          videoPromise?.then(() => video.pause())
        }
      })
    }, {
      threshold: 0.8,
    })

    videoObserver.value?.observe(howItWorksVideo.value)
  }

  onMounted(setVideoIntersectionObserver)

  onBeforeUnmount(() => {
    videoObserver.value?.disconnect()
    videoObserver.value = null
  })
</script>

<style lang="scss">
.how-it-works {
  width: 100%;

  @include breakpoint(sm) {
    max-width: 1680px;
    margin: 0 auto;
  }
}

.how-it-works__block {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include breakpoint(sm) {
    justify-content: center;
  }
}

.how-it-works__block--description, .how-it-works__block--title {
  text-align: center;
}

.how-it-works__title.light-primary-title {
  margin-bottom: var(--spacing-large);
  text-align: center;
}

.how-it-works__grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto minmax(0, 1fr); // see note below
  grid-column-gap: 10px;
  grid-row-gap: 16px;

  @include breakpoint(sm) {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto minmax(0, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;
  }
}

.how-it-works__block--1 {
  grid-area: 2 / 1 / 3 / 2;

  @include breakpoint(sm) {
    grid-area: 1 / 1 / 2 / 4;
  }
}

.how-it-works__block--2 {
  grid-area: 4 / 2 / 5 / 3;

  @include breakpoint(sm) {
    grid-area: 2 / 1 / 4 / 3;
  }
}

.how-it-works__block--3 {
  grid-area: 4 / 1 / 5 / 2;

  @include breakpoint(sm) {
    grid-area: 4 / 1 / 5 / 4;
  }
}

.how-it-works__block--4 {
  grid-area: 1 / 1 / 2 / 3;

  @include breakpoint(sm) {
    grid-area: 2 / 3 / 4 / 5;
  }
}

.how-it-works__block--5 {
  grid-area: 2 / 2 / 3 / 3;

  @include breakpoint(sm) {
    grid-area: 1 / 4 / 2 / 7;
  }
}

.how-it-works__block--6 {
  grid-area: 3 / 1 / 4 / 2;

  @include breakpoint(sm) {
    grid-area: 2 / 5 / 4 / 7
  }
}

.how-it-works__block--7 {
  grid-area: 3 / 2 / 4 / 3;

  @include breakpoint(sm) {
    grid-area: 4 / 4 / 5 / 7
  }
}

.how-it-works__center-block {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.how-it-works__video-wrapper {
  border-radius: 100%;
  overflow: hidden;
  max-width: 320px;
  max-height: 320px;
  display: flex;
  justify-content: center;
  aspect-ratio: 1;

  @include breakpoint(sm) {
    max-width: 500px;
    max-height: 500px;
  }
}

.how-it-works__video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.how-it-works__block--image {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-lightest);
  color: var(--color-primary);
  border: 10px solid var(--color-secondary);
  margin-bottom: var(--spacing-smaller);
}

h2.how-it-works__block--title {
  margin-bottom: var(--spacing-smallest);
}

.how-it-works__block--description {
  @include breakpoint(sm) {
    max-width: 300px;
  }
}

.how-it-works--dark .how-it-works__block--title {
  color: var(--color-secondary)
}

.how-it-works--dark .how-it-works__block--description {
  color: var(--color-lightest)
}

.how-it-works--dark .how-it-works__title.light-primary-title {
  color: var(--color-lightest)
}
</style>
